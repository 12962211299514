<template>
  <div>
    <!-- 先审后投 -->
    <!-- 超管号同时 -->
    <originlity v-if="this.auditType == 1 || isSuperAdmin"></originlity>
    <!-- 先投后审 -->
    <BeforeReview v-else></BeforeReview>
    <selectAuditType v-if="!isSuperAdmin"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import originlity from './components/originality'
import BeforeReview from './components/BeforeReview'
import selectAuditType from './components/SelectAuditType'
export default {
  components: { originlity, BeforeReview, selectAuditType },
  computed: {
    ...mapState({
      // 权限路由集合
      auditType: (state) => state.user.auditType,
      isSuperAdmin: (state) => state.user.roles && state.user.roles[0] === 'SUPER_ADMIN'
    })
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
