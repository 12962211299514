<template>
  <div class="sckz-container">
    <SckzSearch @handleSearch="handleSearch"/>
    <SckzTable ref="table"/>
  </div>
</template>

<script>
import SckzSearch from './SckzSearch'
import SckzTable from './SckzTable'
export default {
  components: { SckzSearch, SckzTable },
  methods: {
    handleSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealQuery(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.sckz-container{
margin: 0 10px;
}
</style>
